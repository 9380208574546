
  export default {
    name: "ConfirmPasswordField",
    props: [ 
      "id_password",
      "label",
      "id_confirm_password",
      "label_confirm",
      "placeholder_password",
      "placeholder_confirm",
    ],
    emits: [
      'update:password',
    ],
    data() {
      return {
        password: null,
        confirm_password: null,
      }
    },
    components: {
      PasswordField: () => import('@/components/form/PasswordField')
    },
    methods: {
      validatePassword() {
        const password = document.getElementById("password")
        const confirm_password = document.getElementById("confirm_password");
        if(password.value != confirm_password.value) {
          confirm_password.setCustomValidity("Les mots de passe ne correspondent pas");
        } else {
          confirm_password.setCustomValidity('');
        }
      },
      emitUpdate(event) {
        this.$root.$emit('update:password', event)
      }
    }
  }
